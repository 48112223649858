import firebase from 'firebase'
import 'firebase/firestore'

// firebase init goes here
const config = {
  apiKey: 'AIzaSyA29nmBaf8Iypt-nytUOOko1MhZShztcHg',
  authDomain: 'guild-stacks.firebaseapp.com',
  projectId: 'guild-stacks',
  storageBucket: 'guild-stacks.appspot.com',
  messagingSenderId: '864434439822',
  appId: '1:864434439822:web:e447338d45653b0f80ebdf',
  measurementId: 'G-V61XMBL73V'
}
firebase.initializeApp(config)

// firebase utils
const auth = firebase.auth()
const currentUser = auth.currentUser
const db = firebase.firestore()
const storage = firebase.storage()

// firebase collections
const usersCollection = db.collection('users')

export {
  auth,
  currentUser,
  db,
  storage,
  usersCollection
}
